<template>
  <div>
    <b-modal
      id="modal-reservation-modify-update-baggage"
      ref="modalReservationModifyBaggage"
      header-bg-variant="light-info"
      header-class="p-75"
      body-class="px-75"
      footer-class="p-50 mt-2"
      no-close-on-backdrop
      no-enforce-focus
      centered
      size="lg"
      @hide="handleHideModal"
      @show="handleOpenModal"
    >
      <template #modal-header>
        <div class="fw-700 my-50 text-airline">
          {{ ['VJ'].includes(bookingSource) ? 'Chọn gói hành lý nâng cấp' : $t('reservation.selectedExtraBaggage') }}
        </div>
        <div :class="`fw-700 my-50 ${['VJ'].includes(bookingSource) ? 'text-danger' : 'text-airline'}`">
          {{ formatCurrency(totalPrice) || 0 }} VND
        </div>
      </template>
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-secondary"
          pill
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25"
          pill
          @click="openSelectPayOptionModalHandle"
        >
          <span class="align-middle px-2">{{ $t('reservation.continue') }}</span>
        </b-button>
      </template>

      <!-- ANCHOR - Body -->
      <div style="min-height: 70px;">
        <div
          v-if="ssrBagsData === null"
          class="text-center my-3"
        >
          <div>
            <i class="text-info">
              Đang lấy dữ liệu, vui lòng đợi...
            </i>
          </div>
          <b-spinner
            class="mt-1"
            variant="info"
          />
        </div>

        <div v-else-if="!isEmpty(ssrBagsData)">
          <div v-if="!isEmpty(baggageOptions)">
            <div class="">
              <b-alert
                variant="warning"
                class="py-50 px-1 mb-2 font-weight-bolder text-airline"
                show
              >
                <span>
                  {{ ['VJ'].includes(bookingSource) ? 'Nâng cấp gói hành lý Vietjet Air: ' : 'Sử dụng nút tăng hoặc giảm để điều chỉnh số lượng gói hành lý theo mong muốn.' }}
                </span>
                <p class="font-weight-bolder">
                  <span v-if="['VJ'].includes(bookingSource)">
                    - Số tiền <span class="text-danger fw-700">thanh toán</span> là tiền chênh lệch của gói cũ và gói mới.
                  </span>
                  <span v-else>
                    Số lượng gói hành lý hiển thị là những gói hành lý chưa được thanh toán.
                  </span>
                </p>

                <p
                  v-if="dataPaxInTrip && dataPaxInTrip.segments"
                  class="font-weight-bolder"
                >
                  - Hành trình: <span class="text-danger fw-700">{{ `${dataPaxInTrip.segments[0].departure.iataCode}-${dataPaxInTrip.segments[dataPaxInTrip.segments.length - 1].arrival.iataCode}` }} ({{ convertISODateTime(dataPaxInTrip.segments[0].departure.at, dataPaxInTrip.segments[0].departure.timeZone).dateTime }})</span>
                </p>

                <p
                  v-if="['VJ'].includes(bookingSource) && oldBaggToUpdateVJ"
                  class="font-weight-bolder"
                >
                  <span class="">- Gói cũ: </span> <span class="text-danger fw-700">{{ resolveSsrNameByCode(oldBaggToUpdateVJ.serviceInfo.description) }}</span>
                </p>
              </b-alert>

              <b-row
                v-for="(baggage, index) of baggageOptions"
                :key="index"
                class="justify-content-between align-items-center border-bottom py-50"
              >
                <b-col>
                  <p class="text-dark font-weight-bolder mb-50">
                    {{ resolveSsrBagsName(baggage) }}
                  </p>
                  <small class="text-secondary">
                    {{ formatCurrency(baggage.fares[0].paxType === 'ALL' ? baggage.fares[0].total : baggage.fares.find(bag => bag.paxType === dataPaxInTrip.passenger.paxType).total) }} VND
                  </small>
                </b-col>

                <b-col>
                  <div class="d-flex justify-content-end align-items-center">
                    <b-button
                      :id="`btn-decrease-${baggage.ssrId}`"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon rounded-circle p-50"
                      :disabled="dataToChange[index].amount === 0"
                      @click="decrease(index)"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                    <span class="d-inline-block mx-1">
                      {{ dataToChange[index].amount }}
                    </span>
                    <b-button
                      :id="`btn-increase-${baggage.ssrId}`"
                      v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                      variant="flat-info"
                      class="btn-icon rounded-circle p-50"
                      :disabled="totalAmountToSelect === 10"
                      @click="increase(index, baggage)"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>

          <div v-else>
            <b-card class="text-warning">
              {{ $t('reservation.noBaggage') }}
            </b-card>
          </div>
        </div>

        <div v-else>
          <b-card class="text-warning">
            {{ $t('reservation.noBaggage') }}
          </b-card>
        </div>
      </div>
    </b-modal>

    <ModalAncillaryPayOptions
      v-if="reservationsData"
      :reservations-data="reservationsData"
      :is-pay-now.sync="isPayNow"
      :has-paid-trip="hasPaidTrip"
      :place="''"
      @submit="handleEditBaggage"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BSpinner,
  BCard,
  BAlert,
} from 'bootstrap-vue'
import {
  computed,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import { isEmpty, cloneDeep } from 'lodash-es'
import Vue from 'vue'

import { resolveSsrBagsName, resolveSsrNameByCode } from '@/constants/selectOptions'
import i18n from '@/libs/i18n'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import store from '@/store'

import { formatCurrency, convertISODateTime } from '@core/utils/filter'
import { validatorPaymentPassword } from '@core/utils/validations/validators'

import useReservation from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'
import ModalAncillaryPayOptions from '@reservation/reservation-modify/components/detail/components/ModalAncillaryPayOptions.vue'

import useToast from '@useToast'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BSpinner,
    BCard,
    BAlert,

    ModalAncillaryPayOptions,
  },
  props: {
    reservationsData: {
      type: Object,
      default: () => {},
    },
    dataPaxInTrip: {
      type: Object,
      default: () => {},
    },
    // isOpenModalEditBag: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  setup(props, { emit, root }) {
    // const toast = useToast()
    const { toastError } = useToast()
    const { dataPaxInTrip } = toRefs(props)
    const VJ_2KG_BAGGAGE_NAME = 'Cabin Extra 2k'

    const {
      modifyAncillary,
      fetchListAncillary,
      getBaggageWeightBySsrPaid,
    } = useReservation()

    const {
      fnAncillary,
      bookingSource,
      sortBaggagesAirlines,
      sortBaggagesVJ,
    } = useAncillaryServicesHandle()

    const loading = ref(true)
    const ssrBagsData = ref(null)
    const errorBagData = ref(false)

    const baggageOptions = ref([])
    const dataToChange = ref([]) // Số lượng gói để thay đổi
    const defaultDataBeforeChange = ref([]) // Số lượng đã chọn ban đầu
    const hasPaidTrip = ref(false) // trạng thái thanh toán của trip
    const isPayNow = ref(false)

    const oldBaggToUpdateVJ = computed(() => {
      if (['VJ'].includes(bookingSource.value)) {
        const oldBagg = dataPaxInTrip.value?.ssrItems?.find(item => !VJ_2KG_BAGGAGE_NAME.includes(item?.serviceInfo?.description))
        return oldBagg || null
      }
      return null
    })

    const totalPrice = ref(0)

    watch(dataToChange, val => {
      const isSelected = ['VJ'].includes(bookingSource.value) ? val.filter(i => i.amount > 0) : val.filter((item => item.data))
      if (!isEmpty(isSelected)) {
        totalPrice.value = ['VJ'].includes(bookingSource.value) ? (isSelected[0].data.fares[0].total - oldBaggToUpdateVJ.value.totalPrice) : isSelected.reduce((total, ssrs) => total + (ssrs.data.fares[0].paxType === 'ALL' ? ssrs.data.fares[0].total : ssrs.data.fares.find(fare => fare.paxType === props.dataPaxInTrip.passenger.paxType).total * ssrs.amount), 0) || 0
      } else {
        totalPrice.value = 0
      }
    }, { deep: true })

    function resolveOldSsrData(oldSsrArr, ssr, data) {
      const existSsrNotPaid = oldSsrArr.find(item => item.serviceSubCode === ssr.code && item.status === 'HD')
      switch (data) {
        case 'amount':
          return existSsrNotPaid ? existSsrNotPaid.numberOfItems : 0

        case 'id':
          return existSsrNotPaid ? existSsrNotPaid.id : null

        default:
          return null
      }
    }

    watch(
      [() => props.dataPaxInTrip, () => ssrBagsData.value],
      ([newDataPaxInTrip, newSsrBagsData]) => {
        if (!isEmpty(newDataPaxInTrip) && !isEmpty(newSsrBagsData)) {
          let resBaggFilter
          if (['VJ'].includes(bookingSource.value)) {
            // const oldBagItemName = dataPaxInTrip.value?.ssrItems?.find(item => !VJ_2KG_BAGGAGE_NAME.includes(item.serviceInfo.description))?.serviceInfo?.description || 'null'
            // resBaggFilter = newSsrBagsData.filter(b => !VJ_2KG_BAGGAGE_NAME.includes(b.code) && Number(b.details[0].weight) > getBaggageWeightBySsrPaid(bookingSource.value, oldBagItemName))

            // NOTE: VJ update baggage: filter theo giá, cho nâng lên gói có GIÁ CAO HƠN (a Tuấn confirm)
            const oldBagItemPrice = dataPaxInTrip.value?.ssrItems?.find(item => !VJ_2KG_BAGGAGE_NAME.includes(item.serviceInfo.description))?.totalPrice ?? null
            if (oldBagItemPrice === null) {
              resBaggFilter = []
            } else {
              resBaggFilter = newSsrBagsData.filter(bag => !isEmpty(bag.fares) && bag.fares[0].total && (bag.fares[0]?.total > oldBagItemPrice))
            }
          } else {
            resBaggFilter = newSsrBagsData
          }
          const listBaggageFilter = resBaggFilter.filter(bag => bag.segmentIds.some(segmentId => segmentId === newDataPaxInTrip.segments[0].devAddonsSegmentIndex) && bag.airline === newDataPaxInTrip.segments[0].airline)

          if (!isEmpty(listBaggageFilter)) {
            dataToChange.value = []

            const undefinedDetailsItem = listBaggageFilter.some(item => isEmpty(item.details))
            if (undefinedDetailsItem) return

            baggageOptions.value = ['VJ'].includes(bookingSource.value)
              ? listBaggageFilter.map((bag, index) => ({ ...bag, ssrId: index + 1 })).sort(sortBaggagesVJ)
              : listBaggageFilter.map((bag, index) => ({ ...bag, ssrId: index + 1 })).sort(sortBaggagesAirlines)

            baggageOptions.value.forEach((item, index) => {
              const data = {
                index,
                amount: resolveOldSsrData(newDataPaxInTrip.ssrItems, item, 'amount'),
                data: item,
                oldSsrId: resolveOldSsrData(newDataPaxInTrip.ssrItems, item, 'id'),
              }

              dataToChange.value.push(cloneDeep(data))
              defaultDataBeforeChange.value.push(cloneDeep(data))
            })
          }

          const arrTktEticketByPassenger = newDataPaxInTrip.passenger.eticket.filter(e => (e.ticketType === 'TKT') && (['OK', 'CKIN'].includes(e.status)))
          if (!isEmpty(arrTktEticketByPassenger)) {
            // check segment[0]
            const segment = newDataPaxInTrip.segments[0]
            hasPaidTrip.value = !!arrTktEticketByPassenger.find(e => e.segments.some(s => s.departure === segment.departure.iataCode && new Date(s.departureDate).toISOString() === segment.departure.at))
          }
        }
      },
    )

    // === HANDLE INCREASE, DECREASE ============================
    const totalAmountToSelect = computed(() => dataToChange.value.reduce((total, item) => total + item.amount, 0))
    const defaultAmountBaggagePaidVN = computed(() => (['1S', '1S_CTRL'].includes(bookingSource.value) && !isEmpty(props.dataPaxInTrip.ssrItems.filter(item => item.status === 'HI')))
      ? props.dataPaxInTrip.ssrItems.filter(item => item.status === 'HI').reduce((total, item) => total + item.numberOfItems, 0)
      : 0)

    function increase(index) {
      const selected = dataToChange.value[index]
      const totalAmountDataChange = ['1S', '1S_CTRL'].includes(bookingSource.value) ? dataToChange.value.reduce((total, item) => total + item.amount, 0) : dataToChange.value[index].amount
      const checkAmount = totalAmountDataChange + defaultAmountBaggagePaidVN.value

      if (checkAmount < fnAncillary.value.BAGGAGE.maximumQuantityOnEach) {
        if (['1S', '1S_CTRL'].includes(bookingSource.value)) {
          selected.amount += 1
        } else {
          dataToChange.value.forEach(item => {
            if ((item.index !== selected.index) && item.amount !== 0) {
              item.amount = 0
            }
          })
          selected.amount += 1
        }
      }
    }

    function decrease(index) {
      if (dataToChange.value[index].amount === 0) return
      dataToChange.value[index].amount -= 1
    }
    // === !HANDLE INCREASE, DECREASE ============================

    // === HANDLE SUBMIT ============================
    function resolvePayload(oldData, newData) {
      const result = []
      let nData

      if (['VJ'].includes(bookingSource.value)) {
        nData = newData.filter(i => i.amount > 0)
      } else {
        nData = newData
      }

      nData.forEach(newItem => {
        const getOldData = oldData.find(data => data.index === newItem.index) // FIXME - undefined
        switch (true) {
          case ['VJ'].includes(bookingSource.value):
            // eslint-disable-next-line no-case-declarations
            const itemToUpdate = {
              ancillaryCode: newItem.data.code,
              ancillaryValue: newItem.data.ssrValue,
              quantity: 1,
              total: newItem.data.fares[0].total,
              details: newItem.data.details.map(detail => ({
                weight: String(detail.weight),
                unit: detail.unit,
              })),
              operation: 'UPDATE',
              id: oldBaggToUpdateVJ.value.id,
            }

            result.push(itemToUpdate)
            break

          case (newItem.amount > getOldData.amount) && (getOldData.amount === 0):
            // ADD
            // eslint-disable-next-line no-case-declarations
            const itemAdd = {
              ancillaryCode: newItem.data.code,
              ancillaryValue: newItem.data.ssrValue ? newItem.data.ssrValue : '',
              quantity: Number(newItem.amount),
              fares: newItem.data.fares,
              details: newItem.data.details.map(detail => ({
                weight: String(detail.weight),
                unit: detail.unit,
              })),
              operation: 'ADD',
              id: '999',
            }

            result.push(itemAdd)
            break

          case newItem.amount < getOldData.amount && (newItem.amount === 0):
            // DELETE ALL
            // eslint-disable-next-line no-case-declarations
            const itemDelete = {
              quantity: getOldData.amount,
              operation: 'DELETE',
              id: getOldData.oldSsrId,
            }

            result.push(itemDelete)
            break

          case newItem.amount < getOldData.amount && (newItem.amount !== 0):
            // EDIT DOWN
            // eslint-disable-next-line no-case-declarations
            const itemEditDown = [
              {
                quantity: getOldData.amount,
                operation: 'DELETE',
                id: getOldData.oldSsrId,
              },
              {
                ancillaryCode: newItem.data.code,
                ancillaryValue: newItem.data.ssrValue ? newItem.data.ssrValue : '',
                quantity: Number(newItem.amount),
                fares: newItem.data.fares,
                details: newItem.data.details.map(detail => ({
                  weight: String(detail.weight),
                  unit: detail.unit,
                })),
                operation: 'ADD',
                id: '999',
              },
            ]

            result.push(...itemEditDown)
            break

          case newItem.amount > getOldData.amount && (getOldData.amount !== 0):
            // EDIT UP
            // eslint-disable-next-line no-case-declarations
            const itemEditUp = [
              {
                quantity: getOldData.amount,
                operation: 'DELETE',
                id: getOldData.oldSsrId,
              },
              {
                ancillaryCode: newItem.data.code,
                ancillaryValue: newItem.data.ssrValue ? newItem.data.ssrValue : '',
                quantity: Number(newItem.amount), // (Number(newItem.amount) + Number(getOldData.amount)),
                fares: newItem.data.fares,
                details: newItem.data.details.map(detail => ({
                  weight: String(detail.weight),
                  unit: detail.unit,
                })),
                operation: 'ADD',
                id: '999',
              },
            ]

            result.push(...itemEditUp)
            break

          default:
            // Do nothing
            break
        }
      })

      return result
    }

    function hasChanges(defaultData, newData) {
      return defaultData.some(item => item.index === newData.index && item.amount !== newData.amount)
    }

    function checkForChanges(dataToCheck, referenceData) {
      return dataToCheck.some(newData => hasChanges(referenceData, newData))
    }

    function getItineraries(segments, passenger) {
      const result = []

      segments.forEach(seg => {
        result.push({
          airline: seg.airline,
          departure: seg.departure.iataCode,
          arrival: seg.arrival.iataCode,
          departureDate: convertISODateTime(seg.departure.at, seg.departure.timeZone).ISOdatetime,
          arrivalDate: convertISODateTime(seg.arrival.at, seg.arrival.timeZone).ISOdatetime,
          flightNumber: seg.flightNumber,
          segmentId: seg.segmentId,
          fareBasisCode: passenger?.fareBasisCode?.split(' | ')[Number(seg.segmentId) - 1] || '',
          bookingClass: seg.bookingClass || '',
          groupClass: seg.groupClass || '',
          bookingStatus: seg.bookingStatus,
          ...(['VJ'].includes(bookingSource.value) && { itineraryId: seg.itineraryId }),
        })
      })

      return result
    }

    function wrapPayload() {
      const { segments, passenger } = props.dataPaxInTrip
      const payload = resolvePayload(defaultDataBeforeChange.value, dataToChange.value, segments, passenger)

      const ssrData = payload.map(item => ({
        ...item,
        paxInfo: {
          type: passenger.paxType,
          paxId: passenger.paxId,
          firstName: passenger.firstName,
          lastName: passenger.lastName,
        },
        itineraries: getItineraries(segments, passenger),
        total: ['VJ'].includes(bookingSource.value) ? item.total : (item?.fares?.find(f => f.paxType === 'ALL' || f.paxType === passenger.paxType)?.total * item.quantity) || 0,
      }))

      return ssrData
    }

    async function openSelectPayOptionModalHandle() {
      const isChange = checkForChanges(defaultDataBeforeChange.value, dataToChange.value)
      if (!isChange) {
        toastError({
          title: 'Vui lòng lựa chọn số lượng cần thay đổi trước khi xác nhận!',
        })
        return
      }

      this.$bvModal.show('modal-modify-ancillary-pay-options')
    }

    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])

    async function handleEditBaggage() {
      const data = await wrapPayload()

      let paymentPassword
      if (getMeEnablePaymentPassword.value && isPayNow.value) {
        paymentPassword = await Vue.swal({
          title: this.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password" style="z-index: 1099;"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          allowOutsideClick: true,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: this.$t('confirmation'),
          cancelButtonText: this.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
      }

      const paymentData = {
        isPayNow: isPayNow.value,
        paymentPassword: paymentPassword?.value || '',
      }

      this.$bvModal.show('modal-api-loading')
      modifyAncillary(props.reservationsData, data.flat(), paymentData)
        .then((() => {
          const modalShow = Array.from(window.document.querySelectorAll('.modal.show'))
            .map(el => el.id).filter(id => id === 'modal-reservation-modify-update-baggage'
            || id === 'modal-modify-ancillary-pay-options')

          modalShow.forEach(id => {
            root.$root.$emit('bv::hide::modal', id)
          })

          // this.$bvModal.hide('modal-reservation-modify-update-baggage')
        }))
        .finally(() => this.$bvModal.hide('modal-api-loading'))
    }
    // === !HANDLE SUBMIT ============================

    // === HANDLE MODAL ============================
    function handleOpenModal() {
      errorBagData.value = false
      loading.value = true
      if (!ssrBagsData.value) {
        fetchListAncillary(props.reservationsData)
          .then(res => {
            if (isEmpty(res) || (res.ssrBags && isEmpty(res.ssrBags))) {
              toastError({
                title: 'Không có dịch vụ trên hành trình!',
              })
            } else {
              ssrBagsData.value = res.ssrBags
            }
          })
          .catch(() => {
            errorBagData.value = true
          })
          .finally(() => {
            loading.value = false
          })
      } else {
        loading.value = false
      }
    }

    function handleHideModal() {
      baggageOptions.value = []
      dataToChange.value = []
      defaultDataBeforeChange.value = []

      emit('update:isOpenModalEditBag', false)
    }

    // === !HANDLE MODAL ============================

    return {
      fnAncillary,
      bookingSource,
      oldBaggToUpdateVJ,
      resolveSsrNameByCode,
      convertISODateTime,

      resolveSsrBagsName,
      handleOpenModal,
      handleHideModal,
      handleEditBaggage,
      decrease,
      increase,

      baggageOptions,
      dataToChange,

      formatCurrency,
      isEmpty,
      totalAmountToSelect,
      ssrBagsData,

      totalPrice,
      isPayNow,
      hasPaidTrip,
      openSelectPayOptionModalHandle,
    }
  },
}
</script>
