var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "modalReservationModifyBaggage",
    attrs: {
      "id": "modal-reservation-modify-update-baggage",
      "header-bg-variant": "light-info",
      "header-class": "p-75",
      "body-class": "px-75",
      "footer-class": "p-50 mt-2",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "centered": "",
      "size": "lg"
    },
    on: {
      "hide": _vm.handleHideModal,
      "show": _vm.handleOpenModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "fw-700 my-50 text-airline"
        }, [_vm._v(" " + _vm._s(['VJ'].includes(_vm.bookingSource) ? 'Chọn gói hành lý nâng cấp' : _vm.$t('reservation.selectedExtraBaggage')) + " ")]), _c('div', {
          class: "fw-700 my-50 ".concat(['VJ'].includes(_vm.bookingSource) ? 'text-danger' : 'text-airline')
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPrice) || 0) + " VND ")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-secondary",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25",
          attrs: {
            "pill": ""
          },
          on: {
            "click": _vm.openSelectPayOptionModalHandle
          }
        }, [_c('span', {
          staticClass: "align-middle px-2"
        }, [_vm._v(_vm._s(_vm.$t('reservation.continue')))])])];
      }
    }])
  }, [_c('div', {
    staticStyle: {
      "min-height": "70px"
    }
  }, [_vm.ssrBagsData === null ? _c('div', {
    staticClass: "text-center my-3"
  }, [_c('div', [_c('i', {
    staticClass: "text-info"
  }, [_vm._v(" Đang lấy dữ liệu, vui lòng đợi... ")])]), _c('b-spinner', {
    staticClass: "mt-1",
    attrs: {
      "variant": "info"
    }
  })], 1) : !_vm.isEmpty(_vm.ssrBagsData) ? _c('div', [!_vm.isEmpty(_vm.baggageOptions) ? _c('div', [_c('div', {}, [_c('b-alert', {
    staticClass: "py-50 px-1 mb-2 font-weight-bolder text-airline",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('span', [_vm._v(" " + _vm._s(['VJ'].includes(_vm.bookingSource) ? 'Nâng cấp gói hành lý Vietjet Air: ' : 'Sử dụng nút tăng hoặc giảm để điều chỉnh số lượng gói hành lý theo mong muốn.') + " ")]), _c('p', {
    staticClass: "font-weight-bolder"
  }, [['VJ'].includes(_vm.bookingSource) ? _c('span', [_vm._v(" - Số tiền "), _c('span', {
    staticClass: "text-danger fw-700"
  }, [_vm._v("thanh toán")]), _vm._v(" là tiền chênh lệch của gói cũ và gói mới. ")]) : _c('span', [_vm._v(" Số lượng gói hành lý hiển thị là những gói hành lý chưa được thanh toán. ")])]), _vm.dataPaxInTrip && _vm.dataPaxInTrip.segments ? _c('p', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" - Hành trình: "), _c('span', {
    staticClass: "text-danger fw-700"
  }, [_vm._v(_vm._s("".concat(_vm.dataPaxInTrip.segments[0].departure.iataCode, "-").concat(_vm.dataPaxInTrip.segments[_vm.dataPaxInTrip.segments.length - 1].arrival.iataCode)) + " (" + _vm._s(_vm.convertISODateTime(_vm.dataPaxInTrip.segments[0].departure.at, _vm.dataPaxInTrip.segments[0].departure.timeZone).dateTime) + ")")])]) : _vm._e(), ['VJ'].includes(_vm.bookingSource) && _vm.oldBaggToUpdateVJ ? _c('p', {
    staticClass: "font-weight-bolder"
  }, [_c('span', {}, [_vm._v("- Gói cũ: ")]), _vm._v(" "), _c('span', {
    staticClass: "text-danger fw-700"
  }, [_vm._v(_vm._s(_vm.resolveSsrNameByCode(_vm.oldBaggToUpdateVJ.serviceInfo.description)))])]) : _vm._e()]), _vm._l(_vm.baggageOptions, function (baggage, index) {
    return _c('b-row', {
      key: index,
      staticClass: "justify-content-between align-items-center border-bottom py-50"
    }, [_c('b-col', [_c('p', {
      staticClass: "text-dark font-weight-bolder mb-50"
    }, [_vm._v(" " + _vm._s(_vm.resolveSsrBagsName(baggage)) + " ")]), _c('small', {
      staticClass: "text-secondary"
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(baggage.fares[0].paxType === 'ALL' ? baggage.fares[0].total : baggage.fares.find(function (bag) {
      return bag.paxType === _vm.dataPaxInTrip.passenger.paxType;
    }).total)) + " VND ")])]), _c('b-col', [_c('div', {
      staticClass: "d-flex justify-content-end align-items-center"
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(234, 84, 85, 0.15)',
        expression: "'rgba(234, 84, 85, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-icon rounded-circle p-50",
      attrs: {
        "id": "btn-decrease-".concat(baggage.ssrId),
        "variant": "flat-danger",
        "disabled": _vm.dataToChange[index].amount === 0
      },
      on: {
        "click": function click($event) {
          return _vm.decrease(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "MinusIcon"
      }
    })], 1), _c('span', {
      staticClass: "d-inline-block mx-1"
    }, [_vm._v(" " + _vm._s(_vm.dataToChange[index].amount) + " ")]), _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(0, 207, 232, 0.15)',
        expression: "'rgba(0, 207, 232, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-icon rounded-circle p-50",
      attrs: {
        "id": "btn-increase-".concat(baggage.ssrId),
        "variant": "flat-info",
        "disabled": _vm.totalAmountToSelect === 10
      },
      on: {
        "click": function click($event) {
          return _vm.increase(index, baggage);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon"
      }
    })], 1)], 1)])], 1);
  })], 2)]) : _c('div', [_c('b-card', {
    staticClass: "text-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.noBaggage')) + " ")])], 1)]) : _c('div', [_c('b-card', {
    staticClass: "text-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.noBaggage')) + " ")])], 1)])]), _vm.reservationsData ? _c('ModalAncillaryPayOptions', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "is-pay-now": _vm.isPayNow,
      "has-paid-trip": _vm.hasPaidTrip,
      "place": ''
    },
    on: {
      "update:isPayNow": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      },
      "update:is-pay-now": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      },
      "submit": _vm.handleEditBaggage
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }